
import {
    Component,
    Vue,
	Prop
} from 'vue-property-decorator';
import {
    DialogInstance,
    DialogOption
} from '@/utils/dialog/DialogService';
import {
    VueConstructor
} from 'vue';

let VUE: VueConstructor
@Component({
    name: "mint-dialog"
})
export default class Dialog extends Vue implements DialogInstance {
    dref: any = null
    ddata: any = null
    closed: boolean = false
    vue: VueConstructor = null
    show: boolean = false

	static init(vue: VueConstructor) {
		VUE = vue;
	}

	 @Prop({
        required: true
    })
    public option: DialogOption;

    mounted() {
        let option = this.option
        let component: any = option.component
        let cname: string = null
        if (component.options && component.options.name) {
            cname = component.options.name.toLowerCase()
        } else {
            cname = option.component.name
        }
        //先注册组件
        if (!cname) {
            console.error(`make sure to provide the "name" option of a component.`)
            return;
        }
        //组件名不得包含大写
        cname = cname.toLowerCase()
        VUE.component(cname, option.component);

        //
        let content = new option.component({
            propsData: {
                dialogref: this,
                dialogdata: option.data || {}
            }
        })
        content.$mount(( < HTMLDivElement > this.$refs.content))

        //触发动画
        setTimeout(() => {
            this.show = true
        })
    }

    close() {
        this.cls(false)
    }

    cls(isMaskClick) {
        if (this.closed) return

        if (isMaskClick && !this.option.closeOnClick) return

        this.closed = true;
        if (this.option.onClose) {
            this.option.onClose()
        }
    }

    $doClose() {
        if (!this.closed) return;
        this.$destroy();
    }

    destroyed() {
        if (this.$el.parentElement) {
            this.$el.remove()
        }
    }
}
