import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
Vue.use(VueRouter)

/* 业务组件 */
import EmptyRoute from "./components/EmptyRoute.vue"
import Index from "./components/Index.vue"
import ChargeLog from "./components/datas/ChargeLog.vue"
import Role from "./components/role/Role.vue"
import Account from "./components/account/Account.vue"
import Group from "./components/group/Group.vue"
import Cars from "./components/car/Cars.vue"
import RechargePlan from "./components/car/RechargePlan.vue"
import Park from "./components/park/Park.vue"
import LoginLog from "./components/LoginLog.vue"
import CarData from "./components/datas/CarData.vue"
import GroupData from "./components/datas/GroupData.vue"
import ParkData from "./components/datas/ParkData.vue"
import ConsumptionRecord from "./components/datas/ConsumptionRecord.vue"
import GroupDel from "./components/group/GroupDel.vue"

const routeConfig: Array<RouteConfig> = [{
	path: '/',
	name: 'index',
	component: Index,
	meta: { title: "数据总览", icon: "icon-index", id: "1_1" }
}, {
	path: '/datas',
	name: 'datas',
	component: EmptyRoute,
	meta: { title: "数据账单", icon: "icon-datas", id: "2" },
	children: [{
		name: 'finance',
		path: '/datas/finance',
		component: ChargeLog,
		meta: { title: "充值记录", icon: "icon-finance", id: "2_1" }
	}, {
		name: 'car_data',
		path: '/datas/car_data',
		component: CarData,
		meta: { title: "车辆数据", icon: "icon-car", id: "2_2" }
	}, {
		name: 'group_data',
		path: '/datas/group_data',
		component: GroupData,
		meta: { title: "群组数据", icon: "icon-group", id: "2_3" }
	}, {
		name: 'park_data',
		path: '/datas/park_data',
		component: ParkData,
		meta: { title: "停车场数据", icon: "icon-parking", id: "2_4" }
	}, {
		name: 'consumption_record',
		path: '/datas/consumption_record',
		component: ConsumptionRecord,
		meta: { title: "消耗记录", icon: "icon-parking", id: "2_5" }
	}]
}, {
	path: '/system',
	name: 'system',
	component: EmptyRoute,
	meta: { title: "系统管理", icon: "icon-system", id: "3" },
	children: [{
		path: '/system/account',
		name: 'account',
		component: Account,
		meta: { title: "账号管理", icon: "icon-account", id: "3_2" }
	}, {
		name: 'role',
		path: '/system/role',
		component: Role,
		meta: { title: "角色管理", icon: "icon-role", id: "3_3" }
	}, {
		name: 'park',
		path: '/system/park',
		component: Park,
		meta: { title: "停车场管理", icon: "icon-parking", id: "3_1" }
	}, {
		path: '/loginlog',
		name: 'loginlog',
		component: LoginLog,
		meta: { title: "登录日志", icon: "icon-login-log", id: "3_4" }
	}]
}, {
	path: '/car',
	name: 'car',
	component: EmptyRoute,
	meta: { title: "车牌管理", icon: "icon-user-manager", id: "4" },
	children: [
		{
		path: '/car/group',
		name: 'group',
		component: Group,
		meta: { title: "群组管理", icon: "icon-group", id: "4_1" }
	}, 
	{
		path: '/car/group_del',
		name: 'group',
		component: GroupDel,
		meta: { title: "群组删除记录", icon: "icon-group", id: "4_1" }
	}, 
	{
		path: '/car/rechargePlan',
		name: 'rechargePlan',
		component: RechargePlan,
		meta: { title: "充值政策", icon: "icon-customer", id: "4_2" }
	}, {
		path: '/car/cars',
		name: 'car',
		component: Cars,
		meta: { title: "车牌列表", icon: "icon-car", id: "4_3" }
	}]
}]

const router = new VueRouter({ routes: routeConfig });

import AppContext from "./utils/AppContext"
router.beforeEach((to, from, next) => {
	if (!AppContext.userInfo || JSON.parse(AppContext.userInfo.permissions).indexOf(to.meta.id) < 0) {
		if(to.path != "/") {
			next({
				path: '/',
			})
		}
		return;
	} else {
		AppContext.appTitle = to.meta.title;
		AppContext.routers = to.matched;
		AppContext.currentRouter = to;
		AppContext.openMenu.length = 0;
		AppContext.openMenu.push(to.matched[0].path);
		next()
		return;
	}
})

export {
	router,
	routeConfig
};