import { VueConstructor } from 'vue/types/umd';
import Dialog from './Dialog.vue';
import DialogNotify from './DialogNotify.vue';
import DialogTip from './DialogTip.vue';
import DialogConfirm from './DialogConfirm.vue';
import DialogLoading from './DialogLoading.vue';
const tipContainer = document.createElement("div");
const dialogContainer = document.createElement("div");
const notifyContainer = document.createElement("div");
const loadingContainer = document.createElement("div");

tipContainer.className = "mint-dialog-tip-container"
notifyContainer.className = "mint-dialog-notify-container"
loadingContainer.className = "mint-dialog-loading-container empty"

let VUE: VueConstructor
let loadingCount = 0

export class DialogService {

	static init(vue: VueConstructor) {
		VUE = vue;
		(<any>Dialog).init(vue)

		document.body.appendChild(tipContainer);
		document.body.appendChild(notifyContainer);
		document.body.appendChild(dialogContainer);
		document.body.appendChild(loadingContainer);
	}

	/**
	 * 
	 * @param msg 
	 * @param callBack 
	 */
	public static tip(option: TipOption): DialogInstance {
		option.msg = option.msg || ""
		option.duration = option.duration >= 0 ? option.duration : 3000
		let tip: any = new DialogTip({
			propsData: {
				option
			}
		})
		tip.$mount()
		//var first = tipContainer.firstChild;//得到页面的第一个元bai素。du    
		//tipContainer.insertBefore(tip.$el, first);//在
		tipContainer.appendChild(tip.$el)
		return tip;
	}

	/**
	 * 
	 * @param msg 
	 * @param callBack 
	 */
	public static loading(option: LoadingOption): LoadingInstance {
		option.msg = option.msg || ""
		if (option.icon === undefined) {
			option.icon = true
		}
		option.delay = option.delay >= 0 ? option.delay : 0
		let loading: any = new DialogLoading({
			propsData: {
				option,
				onShow() {
					if (!loading.closed) {
						loadingCount++
						loadingContainer.classList.remove("empty")
					}
				}
			},
			destroyed() {
				loadingCount--
				if (loadingCount <= 0) {
					loadingContainer.classList.add("empty")
					loadingCount = 0
				}
			},
			mounted() {

			}
		})
		loading.$mount()
		loadingContainer.appendChild(loading.$el)

		return loading;
	}

	/**
	 * 
	 * @param msg 
	 * @param callBack 
	 */
	public static notify(option: NotifyOption): DialogInstance {
		option.msg = option.msg || ""
		option.duration = option.duration >= 0 ? option.duration : 4000
		let notify: any = new DialogNotify({
			propsData: {
				option
			}
		})
		notify.$mount()
		notifyContainer.appendChild(notify.$el)
		return notify;
	}

	/**
	 * 
	 * @param msg 
	 * @param callBack 
	 */
	public static confirm(option: ConfirmOption): ConfirmInstance {
		return DialogService._confirm(option, "confirm")
	}

	/**
	 * 
	 * @param msg 
	 * @param callBack 
	 */
	public static alert(option: ConfirmOption): ConfirmInstance {
		return DialogService._confirm(option, "alert")
	}

	private static _confirm(option: ConfirmOption, type: string): ConfirmInstance {
		option.msg = option.msg || ""
		option.title = option.title || ""
		option.isDanger = option.isDanger || false
		option.reverseBtn = option.reverseBtn || false
		let op: any = Object.assign({}, option)
		op._type = type

		let dia = DialogService.dialog({
			component: DialogConfirm,
			data: op,
			closeOnClick: option.closeOnClick
		})
		return {
			setMsg(msg: string) {
				op.msg = msg
			},
			setTitle(title: string) {
				op.title = title
			},
			close() {
				dia.close()
			}
		}
	}

	/**
	 * 
	 * @param option 
	 */
	public static dialog(option: DialogOption): DialogInstance {
		let dialog: any = new Dialog({
			propsData: {
				option,
			}
		})
		dialog.$mount()
		dialogContainer.appendChild(dialog.$el)
		return dialog;
	}
}

export interface DialogInstance {
	close: () => void
}
export interface LoadingInstance {
	close: () => void
	setMsg: (string) => void
	setProgress: (number) => void
}
export interface ConfirmInstance {
	close: () => void
	setMsg: (string) => void
	setTitle: (string) => void
}
export interface TipInstance {
	close: () => void
	setMsg: (string) => void
	setType: (string) => void
}
export interface DialogOption {
	title?: string
	component: VueConstructor
	hideClose?: boolean
	closeOnClick?: boolean
	data?: object
	onClose?: () => void
	class?: string
}
export interface NotifyOption {
	title?: string
	typs?: string
	msg?: string
	//component?: VueConstructor
	hideClose?: boolean
	data?: object
	duration?: number
	onClose?: () => void
	class?: string
}
export interface ConfirmOption {
	msg?: string
	title?: string
	reverseBtn?: boolean //是否反转按钮
	callBack?: (boolean) => void
	isDanger?: boolean
	closeOnClick?: boolean
}
export interface LoadingOption {
	msg?: string,
	delay?: number,
	icon?: boolean,
	tail?: boolean,
	progress?: number
}
export interface TipOption {
	msg?: string;
	icon?: string;
	type?: string;
	hideClose?: boolean;
	data?: object;
	onClose?: () => void;
	duration?: number
}

