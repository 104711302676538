let timeout;
const AppContext = {
	APP: null,
	showMenu: true,
	appTitle: "",
	routers: [],
	currentRouter: {},
	_userInfo: null,
	_token: '',
	set userInfo(v) {
		this._userInfo = v;
		sessionStorage.setItem("uinfo", JSON.stringify(v));
	},
	get userInfo() {
		if (!this._userInfo) {
			let hdi = sessionStorage.getItem("uinfo");
			if (hdi) {
				try {
					this._userInfo = JSON.parse(hdi);
				} catch (e) {
					this._userInfo = null;
				}
			}
		}
		return this._userInfo;
	},
	openMenu: [],
	authMenu: []
}

export default AppContext;