
import {
    DialogInstance
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';
import AddSubGroup from './AddSubGroup.vue';
import SelectPark from './SelectPark.vue';

@Component
export default class SubGroup extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    item = {}

    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []

    loadData() {
        this.axios.post("/?s=/admin/group/sub_group_list", {
            page: this.currentPage,
            group_id: this.dialogdata.item.id
        }, {
            loading: true
        }).then((d) => {
            this.tableData = d.data.data
            this.pageCount = d.data.last_page
        })
    }

    cancel(row) {
        this.confirm({
            msg: "是否确定取消停车场的管理？",
            callBack: (result) => {
                if (!result) return

                this.$set(row, "loading", true)
                this.axios.post("/?s=/admin/group/remove_park_range", {
                    park_code: row.park_code,
                    group_id: this.dialogdata.item.id
                }, {
                    loading: true
                }).then((d) => {
                    this.loadData()
                })
            }
        })
    }

    addSubGroup() {
        this.dialog({
            title: "添加子群组",
            component: AddSubGroup,
            data: {
                parent: this.dialogdata.item,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

	edit(row) {
        this.dialog({
            title: "编辑群组",
            component: AddSubGroup,
            data: {
				parent: this.dialogdata.item,
                item: row,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    mounted() {
        this.item = this.dialogdata.item || {}
        this.loadData()
    }
}
