
import {
    Component,
    Vue
} from 'vue-property-decorator';
import AddGroup from './AddGroup.vue';
import GroupPark from './GroupPark.vue';
import SubGroup from './SubGroup.vue';

@Component({})
export default class Group extends Vue {
    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []
    searchword = ""

    loadData() {
        this.axios.post("/?s=/admin/group/list_group", {
            page: this.currentPage,
            group_name: this.searchword
        }, {
            loading: true
        }).then((d) => {
            this.tableData = d.data.data
            this.pageCount = d.data.last_page
        })
    }

    //删除群组
    del(row) {
        this.confirm({
            msg: "确认删除群组（" + row.group_name + "）吗？",
            callBack: (result) => {
                if (!result) return
                this.axios.post("/?s=/admin/group/del", {
                    id: row.id
                }, {
                    loading: true
                }).then((d) => {
                    if (d.code == 200) {
                        this.tip({
                            type: "success",
                            msg: "删除成功"
                        })
                        this.loadData()
                    } else {
                        this.tip({
                            type: "fail",
                            msg: d.message
                        })
                    }

                })
            }
        })

    }

    subGroup(row) {
        this.dialog({
            title: "子群组",
            component: SubGroup,
            data: {
                item: row,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    /* 选择停车场 */
    park(row) {
        this.dialog({
            title: "管理停车场",
            component: GroupPark,
            data: {
                item: row,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    add() {
        this.dialog({
            title: "添加群组",
            component: AddGroup,
            data: {
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    edit(row) {
        this.dialog({
            title: "编辑群组",
            component: AddGroup,
            data: {
                item: row,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    mounted() {
        this.loadData()
    }
}
