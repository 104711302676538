
import {
    Component,
    Prop,
    Vue
} from "vue-property-decorator";
import {
    NotifyOption
} from "./DialogService";

@Component({
    name: "mint-dialog-notify",
})
export default class DialogNotify extends Vue {
    @Prop({
        required: true,
    })
    public option: NotifyOption;

    closed: boolean = false;
    show: boolean = false;

    mounted() {
		setTimeout(()=>{
        	this.show = true;
		}, 10)
        setTimeout(() => {
            this.closed = true
        }, this.option.duration)
    }

    close() {
        this.closed = true;
    }

    setMsg(msg) {
        this.$set(this.option, "title", msg)
    }
    setTitle(title) {
        this.$set(this.option, "title", title)
    }

    doClose() {
        if (!this.closed) return;
        this.$destroy();
    }

    destroyed() {
        if (this.$el.parentElement) {
            this.$el.remove();
        }
    }
}
