/* 
	格式时间戳
	{{order.add_time | df("yyyy-MM-dd hh:mm")}}
*/
export function df(value, fmt) {
	if (!value) {
		return ''
	}
	if (!(value instanceof Date) && (value + "").length == 10) {
		value = Number(value) * 1000;
	}
	let getDate = new Date(value);
	let o = {
		'M+': getDate.getMonth() + 1,
		'd+': getDate.getDate(),
		'h+': getDate.getHours(),
		'm+': getDate.getMinutes(),
		's+': getDate.getSeconds(),
		'q+': Math.floor((getDate.getMonth() + 3) / 3),
		'S': getDate.getMilliseconds()
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (getDate.getFullYear() + '').substr(4 - RegExp.$1.length))
	}
	for (let k in o) {
		if (new RegExp('(' + k + ')').test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
		}
	}
	return fmt;
}

export function msToTime(msd) {
	let value = parseInt(msd)

	if (!value) {
		return "0秒";
	}
	if (value > 60 && value < 3600) {
		let m = Math.floor(value / 60)
		return `${m}分 ${value - m*60}秒`
	} else if (value >= 3600) {
		let h = Math.floor(value / 3600)
		let m = Math.floor((value - h * 3600) / 60)
		let s = value - (h*3600 + m * 60)
		return `${h}小时 ${m}分 ${s}秒`
	} else {
		return "0秒"
	}
}