











import {
    Component,
    Vue
} from 'vue-property-decorator';

@Component
export default class Breadcrumb extends Vue {
	ctx = this.appContext
}
