
import {
    Component,
    Vue
} from 'vue-property-decorator';
import AppMenu from "./components/app/AppMenu.vue"
import Login from './components/Login.vue';

@Component({
    components: {
        AppMenu
    }
})
export default class App extends Vue {
    context = this.appContext

    handleCommand(e) {
        sessionStorage.clear();
        location.href = "./";
    }

    mounted() {
        if (!this.appContext.userInfo) {
            this.dialog({
                component: Login,
				class:"login-dialog"
            })
        } else {
            let loading = this.loading({
                icon: false
            })
            this.axios.post("/?s=/admin/user/get_menu").then((data) => {
            }).finally(() => {
                loading.close()
            })
        }
    }
}
