import { DialogOption, DialogService, ConfirmOption, LoadingOption, NotifyOption, TipOption } from './dialog/DialogService';
import { VueConstructor } from 'vue/types/umd';

export default function (vue: VueConstructor) {
	vue.prototype.dialog = function (options: DialogOption) {
		return DialogService.dialog(options)
	}
	vue.prototype.confirm = function (options: ConfirmOption) {
		return DialogService.confirm(options)
	}
	vue.prototype.alert = function (options: ConfirmOption) {
		return DialogService.alert(options)
	}
	vue.prototype.loading = function (options: LoadingOption) {
		return DialogService.loading(Object.assign({
			tail: true
		}, options))
	}
	vue.prototype.notify = function (options: NotifyOption) {
		return DialogService.notify(options)
	}
	vue.prototype.tip = function (options: TipOption) {
		return DialogService.tip(options)
	}
};