
import {
    Component,
    Vue
} from 'vue-property-decorator';
import * as echarts from 'echarts';

@Component
export default class Index extends Vue {
    dynamic = {}
    cumulative = {}

    dates = [
        new Date(new Date().toLocaleDateString()).getTime(),
        new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
    ]

    days = [{
        label: "7 天内",
        val: 7
    }, {
        label: "30 天内",
        val: 30
    }]

    day = 7

    loadData() {
        let data: any = {}

        data.start_time = Math.round(this.dates[0] / 1000)
        data.end_time = Math.round(this.dates[1] / 1000)

        this.axios.post("/?s=/admin/data/overview", data, {
            loading: true
        }).then((d) => {
            this.dynamic = d.data.dynamic
            this.cumulative = d.data.cumulative
        })
    }

    loadData2() {
        this.axios.post("/?s=/admin/data/trend", {
            day: this.day
        }, {
            loading: true
        }).then((res) => {
            console.log(res)
            let data = res.data
            var chart1 = echarts.init(
                this.$el.querySelector(`#datachart`)
            );
            chart1.setOption({
                legend: {
                    data: ['充值时长', '消耗共享时长']
                },
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                yAxis: {
                    type: 'value',
                },
                xAxis: {
                    type: 'category',
                    data: data.map((d) => {
                        return d.date
                    })
                },
                series: [{
                    name: '充值时长',
                    type: 'line',
                    smooth: true,
                    data: data.map((d) => {
                        return d.consume_recharge_time
                    })
                }, {
                    name: '消耗共享时长',
                    type: 'line',
                    smooth: true,
                    data: data.map((d) => {
                        return d.consume_sharing_time
                    })
                }]
            });

            let timeout
            window.onresize = function () {
                clearTimeout(timeout);
                timeout = setTimeout(function () {
                    chart1.resize();
                }, 100);
            }
        })
    }

    mounted() {
        this.loadData()
        this.loadData2()
    }
}
