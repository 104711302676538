
import {
    DialogInstance
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component
export default class AddSubGroup extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    id = null
    name = ""
    remark = ""
    parent = null

    save() {
        if (this.id) {
            this.axios.post("/?s=/admin/group/edit_sub_group", {
                id: this.id,
                group_name: this.name,
                remarks: this.remark,
            }, {
                loading: true
            }).then(() => {
                this.dialogdata.callBack()
                this.dialogref.close()
            })
        } else {
            this.axios.post("/?s=/admin/group/add_sub_group", {
                group_name: this.name,
                remarks: this.remark,
                group_id: this.parent.id
            }, {
                loading: true
            }).then(() => {
                this.dialogdata.callBack()
                this.dialogref.close()
            })
        }
    }

    mounted() {
        this.parent = this.dialogdata.parent
        let item = this.dialogdata.item || {}
        this.id = item.id
        this.name = item.group_name
        this.remark = item.remarks
    }
}
