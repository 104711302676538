
import {
    DialogInstance
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component
export default class SelectGroup extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []
    searchWord = ""

    //代码触发选择
    pgSelect = false

    loadData() {
        if (this.dialogdata.group_id) {
            this.axios.post("/?s=/admin/group/sub_group_list", {
                page: this.currentPage,
				group_id: this.dialogdata.group_id,
                group_name: this.searchWord
            }, {
                loading: true
            }).then((d) => {
                this.tableData = d.data.data
                this.pageCount = d.data.last_page
            })
        } else {
            this.axios.post("/?s=/admin/group/list_group", {
                page: this.currentPage,
                group_name: this.searchWord
            }, {
                loading: true
            }).then((d) => {
                this.tableData = d.data.data
                this.pageCount = d.data.last_page
            })
        }
    }

    doSelect(row) {
        this.dialogdata.onSelect(row)
        this.dialogref.close()
    }

    mounted() {
        this.loadData()
    }
}
