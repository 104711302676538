
import {
    DialogInstance
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component
export default class AddAccount extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    id = null
    roles = []
    account_name = ""
    account = ""
    password = ""
    role_id = null

    save() {
        if (this.id) {
            this.axios.post("/?s=/admin/user/account_add", {
                id: this.id,
                account_name: this.account_name,
                account: this.account,
                password: this.password,
                role_id: this.role_id
            }, {
                loading: true
            }).then(() => {
                this.dialogdata.callBack()
                this.dialogref.close()
            })
        } else {
            this.axios.post("/?s=/admin/user/account_add", {
                account_name: this.account_name,
                account: this.account,
                password: this.password,
                role_id: this.role_id
            }, {
                loading: true
            }).then(() => {
                this.dialogdata.callBack()
                this.dialogref.close()
            })
        }
    }

    mounted() {
        let item = this.dialogdata.item || {}
        this.id = item.id
        this.account = item.account
        this.role_id = item.role_id
        this.account_name = item.account_name

        this.axios.post("/?s=/admin/user/role_list", {
            nopage: true
        }, {
			loading:true
		}).then((d) => {
            this.roles = d.data
        })
    }
}
