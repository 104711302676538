
import {
    DialogInstance,
    DialogService
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';
import ErrorImportTimes from './ErrorImportTimes.vue';
import ViewCars from './ViewCars.vue';

@Component
export default class Recharge extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    cars = []
    name = ""
    time = null
    money = null
    exclude_open = "false"
    limit_time_open = "false"
    plan_id = null
    dates = []
    planList = []

    view() {
        this.dialog({
            title: "查看车牌",
            component: ViewCars,
            data: {
                cars: this.cars
            }
        })
    }

    save() {
        this.axios.post("/?s=/admin/recharge/do_add_money", {
            car_id: JSON.stringify(this.cars.map((c) => {
                return c.id
            })),
            recharge_id: this.plan_id,
            recharge_money: this.money,
            recharge_time: this.time,
            start_time: Math.round(this.dates[0] / 1000),
            end_time: Math.round(this.dates[1] / 1000) + 3600*24-1
        }, {
            loading: true
        }).then((d) => {
			this.tip({
				type:"success",
				msg: "充值成功"
			})
            this.dialogref.close()
            this.dialogdata.callBack();
        }).catch((e) => {
            if (e.code == -999) {
                this.dialog({
                    title: "以下数据无法导入",
                    component: ErrorImportTimes,
                    data: {
                        cars: e.data
                    }
                })
            }
        })
    }

    selectPlan(e) {
        this.planList.some((p) => {
            if (p.id == e) {
                this.money = p.recharge_money
                this.time = p.recharge_time
                return true
            } else {
                return false
            }
        })
    }

    mounted() {
        this.cars = this.dialogdata.cars
        this.axios.post("/?s=/admin/recharge/list", {
            nopage: true
        }).then((d) => {
            this.planList = d.data
        })
    }
}
