export default class FileUtil {
	/**
	 * 
	 * @param accept 
	 * 	accept="image/png" or accept=".png" — 只接受 png 图片.
	 *	accept="image/png, image/jpeg" or accept=".png, .jpg, .jpeg" — PNG/JPEG 文件.
	 *	accept="image/*" — 接受任何图片文件类型. 
	 *	accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" — 接受任何 MS Doc 文件类型.
	 * @param isMultiple 是否多选
	 * @param onchange 回调函数
	 */
	public static browser(accept: string, isMultiple: boolean, onchange: (fileList: FileList) => void) {
		let input: any = document.createElement("input");
		input.type = "file";

		if (accept) {
			input.accept = accept;
		}
		if (isMultiple === true) {
			input.multiple = true;
		}
		input.style.display = "none";
		input.onchange = () => {
			onchange(input.files);
			document.body.removeChild(input);
		}
		document.body.appendChild(input);
		input.click();
	}
}