
import {
    DialogService
} from '@/utils/dialog/DialogService';
import {
    Component,
    Vue
} from 'vue-property-decorator';
import SelectGroup from '../SelectGroup.vue';

@Component({})
export default class ConsumptionRecord extends Vue {
    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []
    searchword = ""
    dates = [
        new Date(new Date().toLocaleDateString()).getTime(),
        new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
    ]

    types = {
        1: "停车扣除",
        2: "转入共享时长",
        3: "解绑充值政策",
        4: "删除车牌",
    }

    loadData() {
        let data: any = {
            page: this.currentPage,
            car_num: this.searchword,
            group_id: this.groupName ? this.groupId : "",
            sub_group_id: this.subGroupId
        }

        if (this.dates && this.dates.length > 0) {
            data.start_time = Math.round(this.dates[0] / 1000)
            data.end_time = Math.round(this.dates[1] / 1000)
        }

        this.axios.post("/?s=/admin/data/use_time_record", data, {
            loading: true
        }).then((d) => {
            this.tableData = d.data.data
            this.pageCount = d.data.last_page
        })
    }

    sure(row) {
        this.axios.post("/?s=/admin/data/sure_order", {
            order_num: row.order_num
        }, {
            loading: "正在确认订单"
        }).then((d) => {
            this.loadData()
        })
    }

    groupId = null
    groupName = ""
    subGroupId = null
    subgroups = []

    selectParentGroup() {
        DialogService.dialog({
            title: "选择群组",
            component: SelectGroup,
            data: {
                onSelect: (group) => {
                    if (this.groupId != group.id) {
                        this.subGroupId = null
                    }
                    this.groupId = group.id;
                    this.groupName = group.group_name
                    this.axios.post("/?s=/admin/group/sub_group_list", {
                        group_id: group.id,
                        nopage: true
                    }, {
                        loading: true
                    }).then((d) => {
                        this.subgroups = d.data.data
                    })
                }
            }
        })
    }

    exportExcel() {
        let start_time: any = '';
        let end_time: any = '';
        if (this.dates && this.dates.length > 0) {
            start_time = Math.round(this.dates[0] / 1000);
            end_time = Math.round(this.dates[1] / 1000);
        }
        window.open(`/b/api/?s=/admin/data/use_time_record&token=${this.appContext.userInfo.token}&group_id=${this.groupId||''}&sub_group_id=${this.subGroupId||''}&car_num=${this.searchword}&start_time=${start_time}&end_time=${end_time}`)
    }

    mounted() {
        this.loadData()
    }
}
