import { DialogService } from './dialog/DialogService';
import axios from "axios"
import AppContext from "./AppContext"

/*
 * @Author: your name
 * @Date: 2019-12-28 02:37:54
 * @LastEditTime : 2020-01-20 01:05:00
 * @LastEditors  : Please set LastEditors
 * @Description: In User Settings Edit
 */
import qs from "qs";
axios.defaults.baseURL = "/b/api"
axios.interceptors.request.use((config: any) => {
	if (config.loading) {
		config.loading = DialogService.loading({
			tail:true,
			icon: false
		})
	}
	if (AppContext.userInfo) {
		config.headers.common['token'] = AppContext.userInfo.token;
	}
	if (config.method == "post" && config.data && !(config.data instanceof FormData)) {
		for (let [key, value] of Object.entries(config.data)) {
			if (value instanceof Array) {
				config.data[key] = JSON.stringify(value);
			}
		}
		config.data = qs.stringify(config.data);
	}
	return config;
}, error => {
	return Promise.reject(error);
});

// 配置响应拦截器 
axios.interceptors.response.use((res) => {
	let config: any = res.config
	if (config.loading) {
		config.loading.close()
	}
	let data = res.data
	if (data.code == 200) {
		return Promise.resolve(res.data);
	} else {
		if(data.msg) {
			DialogService.tip({
				type:"error",
				msg: data.msg
			})
		}
		return Promise.reject(res.data);
	}
}, (error) => {
	return Promise.reject(error);
});

export default axios;