import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

/* element ui */
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

/* 弹窗 */
import { DialogService } from './utils/dialog/DialogService';
DialogService.init(Vue)

//通用
import axios from "./utils/axios"
Vue.prototype.axios = axios
import AppContext from "./utils/AppContext";
Vue.prototype.appContext = AppContext

//弹窗
import dialog from "./utils/dialog"
Vue.use(dialog)

//路由
import { router } from './router'
//面包屑导航
import Breadcrumb from "./components/app/Breadcrumb.vue"
Vue.component("breadcrumb", Breadcrumb);

// 批量注册过滤器
import * as filters from './utils/filter';
Object.keys(filters).forEach((key) => {
	Vue.filter(key, filters[key]);
});


new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
