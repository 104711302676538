
import {
    DialogInstance,
    DialogService
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';
import FileUtil from "../../utils/FileUtil";
import ErrorCars from './ErrorCars.vue';
import ErrorImportTimes from './ErrorImportTimes.vue';

@Component
export default class ImportTimes extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;
    excelFile = null
	filename = ""
    selectFile() {
        FileUtil.browser("application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", false, (fs) => {
            this.excelFile = fs[0]
			this.filename = this.excelFile.name
        })
    }

    save() {
        let fd = new FormData()
        fd.append("file", this.excelFile)
        fd.append("op", "import")
		if(this.dialogdata.policyId) {
        	fd.append("recharge_id", this.dialogdata.policyId)
		}
        this.axios.post("/?s=/admin/recharge/do_add_money", fd, {
            loading: "正在导入"
        }).then((d) => {
			this.tip({
				type:"success",
				msg: "充值成功"
			})
            this.dialogref.close()
            this.dialogdata.callBack();
        }).catch((e) => {
            if (e.code == -999) {
                this.dialog({
                    title: "以下数据无法导入",
                    component: ErrorImportTimes,
                    data: {
                        cars: e.data
                    }
                })
            }
        })
    }

    mounted() {}
}
