
import {
    DialogInstance
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component
export default class SelectPark extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []
    searchWord = ""
    parkCodes = []

    //代码触发选择
    pgSelect = false

    loadData() {
        this.axios.post("/?s=/admin/park/list_parking", {
            page: this.currentPage,
            park_name: this.searchWord,
            account_id: this.dialogdata.account_id
        }, {
            loading: true
        }).then((d) => {
            this.pgSelect = true
            this.tableData = d.data.data
            this.pageCount = d.data.last_page

            let table: any = this.$refs.table
            this.$nextTick(() => {
                let pcods = JSON.parse(JSON.stringify(this.parkCodes))
                this.tableData.forEach(row => {
                    if (pcods.indexOf(row.park_code) > -1) {
                        table.toggleRowSelection(row, true);
                    }
                })
                this.pgSelect = false
            })
        })
    }

    addPark() {
        this.axios.post("/?s=/admin/user/add_park_range", {
            account_id: this.dialogdata.account_id,
            park_code: JSON.stringify(this.parkCodes)
        }).then((d) => {
            this.dialogdata.callBack()
            this.dialogref.close()
        })
    }

    onSelect(val) {
        if (this.pgSelect) return

        let allIds = this.tableData.map((row) => {
            return row.park_code
        })
        //清空当前页
        this.parkCodes = this.parkCodes.filter((pCode) => {
            return allIds.indexOf(pCode) < 0
        })
        val.forEach((v) => {
            this.parkCodes.push(v.park_code)
        })
    }

    mounted() {
        this.loadData()
    }
}
