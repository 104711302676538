
import {
    Component,
    Prop,
    Vue,
    Watch
} from 'vue-property-decorator';
import {
  ConfirmInstance,
  ConfirmOption,
    DialogInstance
} from '@/utils/dialog/DialogService';

@Component({
    name: "mint-dialog-confirm"
})
export default class DialogConfirm extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: ConfirmOption;

    @Prop({
        required: true
    })
    public dialogref: ConfirmInstance;

	rvbtn = false

	beforeMount() {
		if(!this.dialogdata) {
			this.dialogdata = {}
		}
		this.rvbtn = this.dialogdata.reverseBtn||false
	}

	cb(btn) {
		let cb = this.dialogdata.callBack
		if(cb) {
			try {
				cb(btn)
			} catch(e) {
				console.error(e)
			}
		}
		this.dialogref.close()
	}
}
