
import {
    DialogService
} from '@/utils/dialog/DialogService';
import {
    Component,
    Vue
} from 'vue-property-decorator';
import SelectGroup from '../SelectGroup.vue';

@Component({})
export default class GroupData extends Vue {
    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []
    searchword = ""

    loadData() {
        this.axios.post("/?s=/admin/data/group_info_list", {
            page: this.currentPage,
            group_id: this.groupName ? this.groupId : "",
        }, {
            loading: true
        }).then((d) => {
            this.tableData = d.data.data
            this.pageCount = d.data.last_page
        })
    }

    sure(row) {
        this.axios.post("/?s=/admin/data/sure_order", {
            order_num: row.order_num
        }, {
            loading: "正在确认订单"
        }).then((d) => {
            this.loadData()
        })
    }

    groupId = null
    groupName = ""

    selectParentGroup() {
        DialogService.dialog({
            title: "选择群组",
            component: SelectGroup,
            data: {
                onSelect: (group) => {
                    this.groupId = group.id;
                    this.groupName = group.group_name
                }
            }
        })
    }

    mounted() {
        this.loadData()
    }
}
