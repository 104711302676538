
import {
    DialogInstance
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component
export default class AddRole extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    id = null
    name = ""
    permission = []
    permissionAll = []

    save() {
        if (this.id) {
            this.axios.post("/?s=/admin/user/role_edit", {
                id: this.id,
                role_name: this.name,
                permissions: JSON.stringify(this.permission)
            }, {
                loading: true
            }).then(() => {
                this.dialogdata.callBack()
                this.dialogref.close()
            })
        } else {
            this.axios.post("/?s=/admin/user/role_add", {
                role_name: this.name,
                permissions: JSON.stringify(this.permission)
            }, {
                loading: true
            }).then(() => {
                this.dialogdata.callBack()
                this.dialogref.close()
            })
        }
    }

    mounted() {
        this.permissionAll = this.dialogdata.permissionAll
        this.permission = this.dialogdata.permission || []
        this.name = this.dialogdata.name || ''
        this.id = this.dialogdata.id || null
    }
}
