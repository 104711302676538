
import {
    DialogInstance
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';
import SelectGroup from './SelectGroup.vue';

@Component
export default class AccountGroup extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    item = {}

    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []

	searchWord = ""

    loadData() {
        this.axios.post("/?s=/admin/user/group_range_list", {
            page: this.currentPage,
            account_id: this.dialogdata.item.id
        }, {
            loading: true
        }).then((d) => {
            this.tableData = d.data.data
            this.pageCount = d.data.last_page
        })
    }

    cancel(row) {
        this.confirm({
            msg: "是否确定取消群组的管理？",
            callBack: (result) => {
                if (!result) return

                this.$set(row, "loading", true)
                this.axios.post("/?s=/admin/user/remove_group_range", {
                    group_id: row.id,
                    account_id: this.dialogdata.item.id
                }, {
                    loading: true
                }).then((d) => {
                    this.loadData()
                })
            }
        })
    }

    addGroup() {
        this.dialog({
            title: "管理停车场",
            component: SelectGroup,
            data: {
                account_id: this.dialogdata.item.id,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    mounted() {
        this.item = this.dialogdata.item || {}
        this.loadData()
    }
}
