
import {
    DialogService
} from '@/utils/dialog/DialogService';
import {
    Component,
    Vue
} from 'vue-property-decorator';
import SelectGroup from '../SelectGroup.vue';

@Component({})
export default class CarData extends Vue {
    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []
    searchword = ""

    loadData() {
        this.axios.post("/?s=/admin/data/car_info_list", {
            page: this.currentPage,
            car_num: this.searchword,
            group_id: this.groupName ? this.groupId : "",
            sub_group_id: this.subGroupId
        }, {
            loading: true
        }).then((d) => {
            this.tableData = d.data.data
            this.pageCount = d.data.last_page
        })
    }

    sure(row) {
        this.axios.post("/?s=/admin/data/sure_order", {
            order_num: row.order_num
        }, {
            loading: "正在确认订单"
        }).then((d) => {
            this.loadData()
        })
    }

    groupId = null
    groupName = ""
    subGroupId = null
    subgroups = []

    selectParentGroup() {
        DialogService.dialog({
            title: "选择群组",
            component: SelectGroup,
            data: {
                onSelect: (group) => {
                    if (this.groupId != group.id) {
                        this.subGroupId = null
                    }
                    this.groupId = group.id;
                    this.groupName = group.group_name
                    this.axios.post("/?s=/admin/group/sub_group_list", {
                        group_id: group.id,
                        nopage: true
                    }, {
                        loading: true
                    }).then((d) => {
                        this.subgroups = d.data.data
                    })
                }
            }
        })
    }

    exportExcel() {
        window.open(`/b/api/?s=/admin/data/car_info_list&token=${this.appContext.userInfo.token}&group_id=${this.groupId||''}&sub_group_id=${this.subGroupId||''}&car_num=${this.searchword}`)
    }

    mounted() {
        this.loadData()
    }
}
