
import {
    Component,
    Vue
} from 'vue-property-decorator';

@Component({})
export default class ParkData extends Vue {
    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []
    searchword = ""

    loadData() {
        this.axios.post("/?s=/admin/data/parking", {
            page: this.currentPage,
            park_name: this.searchword,
        }, {
            loading: true
        }).then((d) => {
            this.tableData = d.data.data
            this.pageCount = d.data.last_page
        })
    }

    mounted() {
        this.loadData()
    }
}
