
import {
    Component,
    Vue
} from 'vue-property-decorator';
import AddAccount from './AddAccount.vue';
import AccountPark from './AccountPark.vue';
import AccountGroup from './AccountGroup.vue';

@Component({})
export default class Account extends Vue {
    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []

    roleMap = {}
    permission = []

    loadData() {
        this.axios.post("/?s=/admin/user/account_list", {
            page: this.currentPage
        }, {
            loading: true
        }).then((d) => {
            this.tableData = d.data.data
            this.pageCount = d.data.last_page
        })
    }

    add() {
        this.dialog({
            title: "添加账号",
            component: AddAccount,
            data: {
                permissionAll: this.permission,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    edit(row) {
        this.dialog({
            title: "编辑账号",
            component: AddAccount,
            data: {
                item: row,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    /* 选择停车场 */
    park(row) {
        this.dialog({
            title: "管理停车场",
            component: AccountPark,
            data: {
                item: row,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    /* group */
    group(row) {
        this.dialog({
            title: "群组管理",
            component: AccountGroup,
            data: {
                item: row,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    del(row) {
        this.confirm({
            msg: "确认删除账号吗？",
            callBack: (result) => {
                if (!result) return
                this.axios.post("/?s=/admin/user/account_delete", {
                    id: row.id
                }).then((d) => {
                    this.loadData()
                })
            }
        })
    }

    mounted() {
        this.loadData()
    }
}
