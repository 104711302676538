
import {
    DialogInstance,
    DialogService
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';
import SelectGroup from '../SelectGroup.vue';
import FileUtil from "../../utils/FileUtil";
import ErrorCars from './ErrorCars.vue';

@Component
export default class AddCar extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    id = null
    dates = []
    group_id = null
    sub_group_id = null

    group_name = null
    sub_group_name = null

    subgroups = []
    carnum = ""
    tel = ""
    username = ""

    addType = 1

    selectParentGroup() {
        DialogService.dialog({
            title: "选择群组",
            component: SelectGroup,
            data: {
                onSelect: (group) => {
                    if (this.group_id != group.id) {
                        this.sub_group_id = null
                        this.sub_group_name = null
                    }
                    this.group_id = group.id;
                    this.group_name = group.group_name
                    this.axios.post("/?s=/admin/group/sub_group_list", {
                        group_id: group.id,
                        nopage: true
                    }, {
                        loading: true
                    }).then((d) => {
                        this.subgroups = d.data.data
                    })
                }
            }
        })
    }

    excelFile = null
	filename = ""
    selectFile() {
        FileUtil.browser("application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", false, (fs) => {
            this.excelFile = fs[0]
			this.filename = this.excelFile.name
        })
    }

    save() {
        let data: any = {
            real_name: this.username,
            tel: this.tel,
            group_id: this.group_id,
            sub_group_id: this.sub_group_id
        }
        if (this.dates.length) {
            data.start_time = Math.round(this.dates[0] / 1000)
            data.end_time = Math.round(this.dates[1] / 1000)+3600*24-1
        }

        if (this.id) {
            data.id = this.id
            this.axios.post("/?s=/admin/car/edit", data, {
                loading: true
            }).then((d) => {
                this.dialogref.close()
                this.dialogdata.callBack();
            })
        } else {
            if (this.addType == 1) {
                data.car_num = this.carnum
                this.axios.post("/?s=/admin/car/add", data, {
                    loading: true
                }).then((d) => {
                    this.dialogref.close()
                    this.dialogdata.callBack();
                })
            } else {
                let fd = new FormData()
                fd.append("file", this.excelFile)
                fd.append("group_id", this.group_id)
                fd.append("sub_group_id", this.sub_group_id)
                this.axios.post("/?s=/admin/car/import_car", fd, {
                    loading: "正在导入"
                }).then((d) => {
                    this.dialogref.close()
                    this.dialogdata.callBack();
                }).catch((e) => {
                    if (e.code == -999) {
                        this.dialog({
                            title: "以下数据无法导入",
                            component: ErrorCars,
                            data: {
                                cars: e.data
                            }
                        })
                    }
                })
            }
        }
    }

    disabled() {
        if (!this.group_id || !this.sub_group_id) {
            return true
        }

        if (this.addType == 1) {
            if (!this.carnum || !this.username) {
                return true
            } else {
                return false
            }
        } else {
            console.log(this.excelFile)
            if (!this.excelFile) {
                return true
            } else {
                return false
            }
        }
    }

    mounted() {
        let car = this.dialogdata.car
        if (car) {
            this.id = car.id
            this.carnum = car.car_num
            this.group_id = car.group_id
            this.sub_group_id = car.sub_group_id

            if (this.group_id) {
                this.axios.post("/?s=/admin/group/sub_group_list", {
                    group_id: this.group_id,
                    nopage: true
                }, {
                    loading: true
                }).then((d) => {
                    this.subgroups = d.data.data
                })
            }

            this.group_name = car.group_name
            this.sub_group_name = car.sub_group_name

            this.tel = car.tel
            this.username = car.real_name
            if (car.start_time && car.end_time) {
                this.dates = [car.start_time * 1000, car.end_time * 1000]
            }
        }
    }
}
