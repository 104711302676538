
import {
    Component,
    Vue
} from 'vue-property-decorator';
import {
    routeConfig
} from "../../router";

@Component
export default class AppMenu extends Vue {
    menu = routeConfig

    appCtx = this.appContext

    get filterMenu() {
        let user = this.appContext.userInfo
        let permissions = JSON.parse(user.permissions);

        let authMenu = []
        this.menu.forEach((m: any) => {
            if (!m.children || !m.children.length) {
                if (permissions.indexOf(m.meta.id) > -1) {
                    authMenu.push(m)
                }
            } else {
                let subms = []
                m.children.forEach((subm) => {
                    if (permissions.indexOf(subm.meta.id) > -1) {
                        subms.push(subm)
                    }
                })
				if (subms.length) {
					authMenu.push({
						...m,
						children: subms
					})
				}
            }
        })

        return authMenu;

    }

    /* 菜单控制相关 */
    handleOpen(m) {
        this.appContext.openMenu.length = 0;
        this.appContext.openMenu.push(m);
    }
    handleClose() {

    }
}
