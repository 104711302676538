
import {
    Component,
    Vue
} from 'vue-property-decorator';
import AddRole from './AddRole.vue';

@Component({})
export default class Role extends Vue {
    pageCount = 0
    currentPage = 1
    tableData = []
    roleMap = {}
    permission = []

    loadData() {
        this.axios.post("/?s=/admin/user/role_list", {
            page: this.currentPage
        }, {
            loading: true
        }).then((d) => {
            this.tableData = d.data.data
            this.pageCount = d.data.last_page
        })
    }

    mapRole(list) {
        if (!list) return []

        return JSON.parse(list).map((l) => {
            return this.roleMap[l]
        })
    }

    addRole() {
        this.dialog({
            title: "添加角色",
            component: AddRole,
            data: {
                permissionAll: this.permission,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    editRole(role) {
        this.dialog({
            title: "编辑角色",
            component: AddRole,
            data: {
                id: role.id,
                name: role.role_name,
                permissionAll: this.permission,
                permission: JSON.parse(role.permissions),
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    del(row) {
        this.confirm({
            msg: "删除后，关联此角色的账号将无法登录，是否确认删除？",
            callBack: (result) => {
                if (!result) return
                this.axios.post("/?s=/admin/user/role_delete", {
                    id: row.id
                }, {
                    loading: true
                }).then((d) => {
                    this.loadData()
                })
            }
        })
    }

    mounted() {
        this.axios.post("/?s=/admin/user/select_menu", null, {
            loading: true
        }).then((d) => {
            d.data.forEach((m) => {
                m.list.forEach((subm) => {
                    this.permission.push(subm)
                    this.roleMap[subm.id] = subm.name
                })
            })
            this.loadData()
        })
    }
}
