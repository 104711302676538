
import {
    DialogService
} from '@/utils/dialog/DialogService';
import {
    Component,
    Vue
} from 'vue-property-decorator';
import SelectGroup from '../SelectGroup.vue';
import AddCar from './AddCar.vue';
import ImportTimes from './ImportTimes.vue';
import Recharge from './Recharge.vue';

@Component({})
export default class Cars extends Vue {
    /* 分页相关 */
    pageCount = 0
    currentPage = 1
    tableData = []
    searchword = ""
    groupId = null
    groupName = ""
    subGroupId = null
    subgroups = []

    selectParentGroup() {
        DialogService.dialog({
            title: "选择群组",
            component: SelectGroup,
            data: {
                onSelect: (group) => {
                    if (this.groupId != group.id) {
                        this.subGroupId = null
                    }
                    this.groupId = group.id;
                    this.groupName = group.group_name
                    this.axios.post("/?s=/admin/group/sub_group_list", {
                        group_id: group.id,
                        nopage: true
                    }, {
                        loading: true
                    }).then((d) => {
                        this.subgroups = d.data.data
                    })
                }
            }
        })
    }

    del(row) {
        this.confirm({
            msg: "确认删除车牌吗？",
            callBack: (result) => {
                if (!result) return
                this.axios.post("/?s=/admin/car/car_delete", {
                    id: row.id
                }).then((d) => {
                    this.loadData()
                })
            }
        })
    }

    unbind(row) {
        this.confirm({
            msg: "确认解绑政策牌吗？",
            callBack: (result) => {
                if (!result) return
                this.axios.post("/?s=/admin/car/car_recharge", {
                    id: row.id
                }).then((d) => {
                    this.loadData()
                })
            }
        })
    }

    add() {
        this.dialog({
            title: "添加车牌号",
            component: AddCar,
            data: {
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    edit(row) {
        this.dialog({
            title: "编辑车牌号",
            component: AddCar,
            data: {
                car: row,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    charge(row) {
        this.dialog({
            title: "充值时长",
            component: Recharge,
            data: {
                cars: [{
                    id: row.id,
                    car_num: row.car_num
                }],
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    mutiCharge() {
        this.dialog({
            title: "充值时长",
            component: Recharge,
            data: {
                cars: this.carSelected,
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    loadData() {
        this.axios.post("/?s=/admin/car/list", {
            page: this.currentPage,
            car_num: this.searchword,
            group_id: this.groupName ? this.groupId : "",
            sub_group_id: this.subGroupId
        }, {
            loading: true
        }).then((d) => {
            this.pgSelect = true

            this.tableData = d.data.data
            this.pageCount = d.data.last_page

            let table: any = this.$refs.table
            this.$nextTick(() => {
                let pcods = this.carSelected.map((car) => {
                    return car.id
                })
                this.tableData.forEach(row => {
                    if (pcods.indexOf(row.id) > -1) {
                        table.toggleRowSelection(row, true);
                    }
                })
                this.pgSelect = false
            })
        })
    }

    //代码触发选择
    pgSelect = false
    carSelected = []
    onSelect(val) {
        if (this.pgSelect) return

        let allIds = this.tableData.map((row) => {
            return row.id
        })
        //清空当前页
        this.carSelected = this.carSelected.filter((car) => {
            return allIds.indexOf(car.id) < 0
        })
        val.forEach((v) => {
            this.carSelected.push({
                id: v.id,
                car_num: v.car_num
            })
        })
    }

    importTimes() {
        this.dialog({
            title: "导入时长",
            component: ImportTimes,
            data: {
                callBack: () => {
                    this.loadData()
                }
            }
        })
    }

    exportExcel() {
        window.open(`/b/api/?s=/admin/car/export_car&token=${this.appContext.userInfo.token}&group_id=${this.groupId||''}&sub_group_id=${this.subGroupId||''}&car_num=${this.searchword}`)
    }

    mounted() {
        this.loadData()
    }
}
