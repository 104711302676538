
import {
    DialogInstance,
    DialogService
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component
export default class AddRechargePlan extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    name = ""
    time = null
    money = null
	exclude_open = "false"
	limit_time_open = "false"

    save() {
        this.axios.post("/?s=/admin/recharge/add", {
			recharge_policy_name: this.name,
			recharge_money: this.money,
			recharge_time: this.time,
			limit_time_open:this.limit_time_open,
			exclude_open:this.exclude_open
        }, {
            loading: true
        }).then((d) => {
            this.dialogref.close()
            this.dialogdata.callBack();
        })
    }

    mounted() {

    }
}
