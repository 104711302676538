
import {
    DialogInstance
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component
export default class Login extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    account = ""
    password = ""

    login() {
        let loading = this.loading({
            msg: "登录",
            icon: false,
            tail: true
        })
        this.axios.post("/?s=/admin/login/main", {
            account: this.account,
            password: this.password
        }).then((data) => {
            if (data.code == 200) {
                this.appContext.userInfo = data.data
                location.reload()
                this.dialogref.close()
            }

        }).finally(() => {
            loading.close()

        })
    }

    mounted() {}
}
