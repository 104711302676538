
import {
    DialogInstance
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component
export default class AddGroup extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    id = null
    name = ""
	remark = ""
	warningTime = 0

    save() {
        if (this.id) {
            this.axios.post("/?s=/admin/group/edit_group", {
                id: this.id,
                group_name: this.name,
                warning_duration: this.warningTime,
                remarks: this.remark,
            }, {
                loading: true
            }).then(() => {
                this.dialogdata.callBack()
                this.dialogref.close()
            })
        } else {
            this.axios.post("/?s=/admin/group/add_group", {
                 group_name: this.name,
                warning_duration: this.warningTime,
                remarks: this.remark,
            }, {
                loading: true
            }).then(() => {
                this.dialogdata.callBack()
                this.dialogref.close()
            })
        }
    }

    mounted() {
        let item = this.dialogdata.item || {}
        this.id = item.id
        this.name = item.group_name
        this.warningTime = item.warning_duration
        this.remark = item.remarks
    }
}
