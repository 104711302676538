
import {
    Component,
    Prop,
    Vue
} from "vue-property-decorator";
import {
    LoadingOption
} from "./DialogService";

@Component({
    name: "mint-dialog-loading",
})
export default class DialogLoading extends Vue {
    @Prop({
        required: true,
    })
    public option: LoadingOption;
    @Prop({
        required: true,
    })
    public onShow: () => void;

    closed: boolean = false;
    show: boolean = false;

    mounted() {
        setTimeout(() => {
            this.show = true;
            this.onShow()
        }, this.option.delay);
    }

    close() {
        this.closed = true;
        //如果当前元素已经不可见，立马销毁
        setTimeout(() => {
            if (!this.show || parseFloat(getComputedStyle(this.$el).opacity) == 0) {
                this.$destroy();
            }
        });
    }

    setMsg(msg) {
        this.option.msg = msg || "";
    }
    setProgress(progress) {
        this.option.progress = progress;
    }

    doClose() {
        if (!this.closed) return;
        this.$destroy();
    }

    destroyed() {
        if (this.$el.parentElement) {
            this.$el.remove();
        }
    }
}
