
import {
    DialogInstance,
} from '@/utils/dialog/DialogService';
import {
    Component,
    Prop,
    Vue
} from 'vue-property-decorator';

@Component({})
export default class ViewCars extends Vue {
    @Prop({
        required: true
    })
    public dialogdata: any;
    @Prop({
        required: true
    })
    public dialogref: DialogInstance;

    cars = []

    mounted() {
        this.cars = this.dialogdata.cars
    }
}
